var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"form"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"sm":"12","xl":"9"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('h3',[_vm._v("Contact info")])]),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"contact-facebook"}},[_vm._v("Company name "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Company name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"id":"company-name","placeholder":"Company name","name":"companyName"},model:{value:(_vm.contactInfo.companyName),callback:function ($$v) {_vm.$set(_vm.contactInfo, "companyName", $$v)},expression:"contactInfo.companyName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"telephone"}},[_vm._v("Telephone "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_vm._l((_vm.contactInfo.telephones),function(_,index){return _c('validation-provider',{key:index,attrs:{"name":("Telephone " + (index + 1)),"rules":"required|regex:^([0-9]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{class:("flex gap-4 " + (index > 0 ? 'mt-8px' : 'mt-0'))},[_c('div',{staticClass:"w-full"},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"id":("telephone" + index),"placeholder":"Telephone","name":("telephone" + index)},model:{value:(_vm.contactInfo.telephones[index]),callback:function ($$v) {_vm.$set(_vm.contactInfo.telephones, index, $$v)},expression:"contactInfo.telephones[index]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1),_c('b-button',{staticClass:"plus-button",attrs:{"variant":"outline-secondary","disabled":index === 2},on:{"click":function () {
                          if (index === 2) { return; }
                          _vm.handleAddOrRemoveArrayClick(_vm.contactInfo.telephones, index, '')
                        }}},[_c('feather-icon',{attrs:{"icon":_vm.isNotLastArrayItem(_vm.contactInfo.telephones, index) ? 'MinusIcon' : 'PlusIcon',"size":"18"}})],1)],1)]}}],null,true)})})],2)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"email"}},[_vm._v("Email "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_vm._l((_vm.contactInfo.emails),function(_,index){return _c('validation-provider',{key:index,attrs:{"name":("Email " + (index + 1)),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('div',{class:("flex gap-4 " + (index > 0 ? 'mt-8px' : 'mt-0'))},[_c('div',{staticClass:"w-full"},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"id":("email" + index),"placeholder":"Email","name":("email" + index)},model:{value:(_vm.contactInfo.emails[index]),callback:function ($$v) {_vm.$set(_vm.contactInfo.emails, index, $$v)},expression:"contactInfo.emails[index]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1),_c('b-button',{staticClass:"plus-button",attrs:{"variant":"outline-secondary","disabled":index === 2},on:{"click":function () {
                          if (index === 2) { return; }
                          _vm.handleAddOrRemoveArrayClick(_vm.contactInfo.emails, index, '')
                        }}},[_c('feather-icon',{attrs:{"icon":_vm.isNotLastArrayItem(_vm.contactInfo.emails, index) ? 'MinusIcon' : 'PlusIcon',"size":"18"}})],1)],1)]}}],null,true)})})],2)],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"sm":"12"}},[_c('h3',[_vm._v("Hr contact info")])]),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"telephone"}},[_vm._v("Telephone "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_vm._l((_vm.contactInfoHr.telephones),function(_,index){return _c('validation-provider',{key:index,attrs:{"name":("HR Telephone " + (index + 1)),"rules":"required|regex:^([0-9]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('div',{class:("flex gap-4 " + (index > 0 ? 'mt-8px' : 'mt-0'))},[_c('div',{staticClass:"w-full"},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"id":("hr-telephone" + index),"placeholder":"Telephone","name":("hr-telephone" + index)},model:{value:(_vm.contactInfoHr.telephones[index]),callback:function ($$v) {_vm.$set(_vm.contactInfoHr.telephones, index, $$v)},expression:"contactInfoHr.telephones[index]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1),_c('b-button',{staticClass:"plus-button",attrs:{"variant":"outline-secondary","disabled":index === 2},on:{"click":function () {
                          if (index === 2) { return; }
                          _vm.handleAddOrRemoveArrayClick(_vm.contactInfoHr.telephones, index, '')
                        }}},[_c('feather-icon',{attrs:{"icon":_vm.isNotLastArrayItem(_vm.contactInfoHr.telephones, index) ? 'MinusIcon' : 'PlusIcon',"size":"18"}})],1)],1)]}}],null,true)})})],2)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"email"}},[_vm._v("Email "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_vm._l((_vm.contactInfoHr.emails),function(_,index){return _c('validation-provider',{key:index,attrs:{"name":("HR Email " + (index + 1)),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('div',{class:("flex gap-4 " + (index > 0 ? 'mt-8px' : 'mt-0'))},[_c('div',{staticClass:"w-full"},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"id":("hr-email" + index),"placeholder":"Email","name":("hr-email" + index)},model:{value:(_vm.contactInfoHr.emails[index]),callback:function ($$v) {_vm.$set(_vm.contactInfoHr.emails, index, $$v)},expression:"contactInfoHr.emails[index]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1),_c('b-button',{staticClass:"plus-button",attrs:{"variant":"outline-secondary","disabled":index === 2},on:{"click":function () {
                          if (index === 2) { return; }
                          _vm.handleAddOrRemoveArrayClick(_vm.contactInfoHr.emails, index, '')
                        }}},[_c('feather-icon',{attrs:{"icon":_vm.isNotLastArrayItem(_vm.contactInfoHr.emails, index) ? 'MinusIcon' : 'PlusIcon',"size":"18"}})],1)],1)]}}],null,true)})})],2)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"hr-lineId"}},[_vm._v("Line ID")]),_c('b-form-input',{attrs:{"id":"hr-lineId","placeholder":"Line ID","name":"hr-lineId"},model:{value:(_vm.contactInfoHr.lineId),callback:function ($$v) {_vm.$set(_vm.contactInfoHr, "lineId", $$v)},expression:"contactInfoHr.lineId"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"hr-line-link-invite"}},[_vm._v("Line Link Invite")]),_c('b-form-input',{attrs:{"id":"hr-line-link-invite","placeholder":"Line Link Invite","name":"hr-lineLinkInvite"},model:{value:(_vm.contactInfoHr.lineLinkInvite),callback:function ($$v) {_vm.$set(_vm.contactInfoHr, "lineLinkInvite", $$v)},expression:"contactInfoHr.lineLinkInvite"}})],1)],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","xl":"9"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('h3',[_vm._v("Business hour")])]),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"business-hour"}},[_vm._v("Business Hour "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Business hour","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors.length > 0 ? false : null,"options":_vm.businessHourOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"business-hour"},model:{value:(_vm.businessHour),callback:function ($$v) {_vm.businessHour=$$v},expression:"businessHour"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.businessHour === 'open')?_c('b-row',{staticClass:"flex"},[_c('b-col',{attrs:{"sm":"12","md":"2"}},[_c('h6',{staticClass:"mt-12px nowrap"},[_vm._v("Monday - Friday")])]),_c('b-col',_vm._l((_vm.businessHourDetail.monday),function(time,index){return _c('validation-provider',{key:index,attrs:{"name":("Businees hour detail " + (index + 1)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('div',{class:("flex w-full gap-4 " + (index > 0 ? 'mt-8px' : 'mt-0'))},[_c('b-form-timepicker',{attrs:{"locale":"en","placeholder":"Time"},model:{value:(time.start),callback:function ($$v) {_vm.$set(time, "start", $$v)},expression:"time.start"}}),_c('feather-icon',{attrs:{"icon":"MinusIcon","size":"40"}}),_c('b-form-timepicker',{attrs:{"locale":"en","placeholder":"Time"},model:{value:(time.end),callback:function ($$v) {_vm.$set(time, "end", $$v)},expression:"time.end"}}),_c('b-button',{staticClass:"plus-button",attrs:{"variant":"outline-secondary","disabled":index === 2},on:{"click":function () {
                            if (index === 2) { return; }
                            _vm.handleAddOrRemoveArrayClick(_vm.businessHourDetail.monday, index, { start: '', end: '' })
                          }}},[_c('feather-icon',{attrs:{"icon":_vm.isNotLastArrayItem(_vm.businessHourDetail.monday, index) ? 'MinusIcon' : 'PlusIcon',"size":"18"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})}),1)],1):_vm._e()],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","xl":"9"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('h3',[_vm._v("Address / Google Map")])]),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"address"}},[_vm._v("Company Address "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Company Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"id":"companyAddress","placeholder":"Company Address","name":"companyAddress"},model:{value:(_vm.addressMap.address),callback:function ($$v) {_vm.$set(_vm.addressMap, "address", $$v)},expression:"addressMap.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"codezip"}},[_vm._v("Code Zip "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Code zip","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors.length > 0 ? false:null,"options":_vm.zipcodeOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"codezip"},on:{"change":_vm.handleZipcodeChange},model:{value:(_vm.addressMap.codezip),callback:function ($$v) {_vm.$set(_vm.addressMap, "codezip", $$v)},expression:"addressMap.codezip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"province"}},[_vm._v("Province "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Province","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors.length > 0 ? false : null,"options":_vm.provinceOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"province"},model:{value:(_vm.addressMap.province),callback:function ($$v) {_vm.$set(_vm.addressMap, "province", $$v)},expression:"addressMap.province"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"state"}},[_vm._v("State "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors.length > 0 ? false : null,"options":_vm.stateOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"state"},on:{"change":_vm.handleStateChange},model:{value:(_vm.addressMap.state),callback:function ($$v) {_vm.$set(_vm.addressMap, "state", $$v)},expression:"addressMap.state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"city"}},[_vm._v("City "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors.length > 0 ? false : null,"options":_vm.cityOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"city"},model:{value:(_vm.addressMap.city),callback:function ($$v) {_vm.$set(_vm.addressMap, "city", $$v)},expression:"addressMap.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"googlemap"}},[_vm._v("Google Map "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Google Map","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"id":"googlemap","placeholder":"Google Map","name":"googlemap","disabled":_vm.maploading},on:{"change":_vm.updateMap},model:{value:(_vm.addressMap.googlemap),callback:function ($$v) {_vm.$set(_vm.addressMap, "googlemap", $$v)},expression:"addressMap.googlemap"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"relative"},[_c('div',{staticClass:"overlay flex justify-center items-center",attrs:{"hidden":!_vm.maploading}},[_c('span',[_vm._v("Loading...")])]),_c('div',{staticClass:"w-full",staticStyle:{"height":"200px"},attrs:{"id":"map"}})])])],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","xl":"9"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('h3',[_vm._v("Social media")])]),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"contact-facebook"}},[_vm._v("Facebook Link "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Facebook link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"id":"contact-facebook","placeholder":"Facebook Link","name":"facebook"},model:{value:(_vm.social.facebook),callback:function ($$v) {_vm.$set(_vm.social, "facebook", $$v)},expression:"social.facebook"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"contact-facebook"}},[_vm._v("Instagram Link "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Instagram link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"id":"contact-instagram","placeholder":"Instagram Link","name":"instagram"},model:{value:(_vm.social.instagram),callback:function ($$v) {_vm.$set(_vm.social, "instagram", $$v)},expression:"social.instagram"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"contact-facebook"}},[_vm._v("Line Link "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Line link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"id":"contact-line","placeholder":"Line Link","name":"line"},model:{value:(_vm.social.line),callback:function ($$v) {_vm.$set(_vm.social, "line", $$v)},expression:"social.line"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"contact-facebook"}},[_vm._v("Linkedin Link "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Linkedin link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"id":"contact-linkedin","placeholder":"Linkedin Link","name":"linkedin"},model:{value:(_vm.social.linkedin),callback:function ($$v) {_vm.$set(_vm.social, "linkedin", $$v)},expression:"social.linkedin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"fixed-footer justify-end"},[_c('div',[_c('b-button',{attrs:{"variant":"outline-secondary"}},[_c('span',[_vm._v("Cancel")])]),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"success"},on:{"click":_vm.handleUpdate}},[_c('span',[_vm._v("Save")])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }