import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import zipcodeOptions from './zipcode.js'

export default function useContact() {
  // Use toast
  const toast = useToast()
  const businessHourOptions = [
    { text: 'No Hour Avaliable', value: 'none' },
    { text: 'Open on select Hour', value: 'open' },
  ]
  const provinceDefaultOptions = [
    { text: 'Select province', value: '' },
  ]
  const stateDefaultOptions = [
    { text: 'Select state', value: '' },
  ]
  const cityDefaultOptions = [
    { text: 'Select city', value: '' },
  ]

  const fetchContact = async () => {
    try {
      const response = await store.dispatch('app-contact/fetchContact');
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching contact details',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const updateContact = async (contact) => {
    try {
      const response = await store.dispatch('app-contact/updateContact', contact);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error update contact details',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const getGeo = async (googlemap) => {
    try {
      const response = await store.dispatch('app-contact/getGeo', googlemap);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error get googlemap geo',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  return {
    toast,
    businessHourOptions,
    provinceDefaultOptions,
    stateDefaultOptions,
    cityDefaultOptions,
    zipcodeOptions,
    getGeo,
    fetchContact,
    updateContact,
  }
}
