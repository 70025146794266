import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchContact() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/contactUsGet')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateContact(_, contact) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/contactUsUpdate`, { ...contact })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getGeo(_, googlemap) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/getGeo`, { url: googlemap })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
