<template>
  <div>
    <validation-observer ref="form">
      <b-form>
        <b-row>
          <b-col sm="12" xl="9">
            <b-card>
              <b-row>
                <b-col sm="12">
                  <h3>Contact info</h3>
                </b-col>
                <b-col sm="12">
                  <b-form-group>
                    <label for="contact-facebook">Company name <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Company name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="contactInfo.companyName"
                        :state="errors.length > 0 ? false:null"
                        id="company-name"
                        placeholder="Company name"
                        name="companyName"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group>
                    <label for="telephone">Telephone <span class="text-red">*</span></label>
                    <validation-provider
                      v-for="(_, index) of contactInfo.telephones"
                      :key="index"
                      #default="{ errors }"
                      :name="`Telephone ${index + 1}`"
                      rules="required|regex:^([0-9]+)$"
                    >
                      <div :class="`flex gap-4 ${index > 0 ? 'mt-8px' : 'mt-0'}`">
                        <div class="w-full">
                          <b-form-input
                            v-model="contactInfo.telephones[index]"
                            :state="errors.length > 0 ? false:null"
                            :id="`telephone${index}`"
                            placeholder="Telephone"
                            :name="`telephone${index}`"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </div>
                        <b-button
                          class="plus-button"
                          variant="outline-secondary"
                          :disabled="index === 2"
                          @click="() => {
                            if (index === 2) return;
                            handleAddOrRemoveArrayClick(contactInfo.telephones, index, '')
                          }"
                        >
                          <feather-icon
                            :icon="isNotLastArrayItem(contactInfo.telephones, index) ? 'MinusIcon' : 'PlusIcon'"
                            size="18"
                          />
                        </b-button>
                      </div>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group>
                    <label for="email">Email <span class="text-red">*</span></label>
                    <validation-provider
                      v-for="(_, index) of contactInfo.emails"
                      :key="index"
                      #default="{ errors }"
                      :name="`Email ${index + 1}`"
                      rules="required|email"
                    >
                      <div :class="`flex gap-4 ${index > 0 ? 'mt-8px' : 'mt-0'}`">
                        <div class="w-full">
                          <b-form-input
                            v-model="contactInfo.emails[index]"
                            :state="errors.length > 0 ? false:null"
                            :id="`email${index}`"
                            placeholder="Email"
                            :name="`email${index}`"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </div>
                        <b-button
                          class="plus-button"
                          variant="outline-secondary"
                          :disabled="index === 2"
                          @click="() => {
                            if (index === 2) return;
                            handleAddOrRemoveArrayClick(contactInfo.emails, index, '')
                          }"
                        >
                          <feather-icon
                            :icon="isNotLastArrayItem(contactInfo.emails, index) ? 'MinusIcon' : 'PlusIcon'"
                            size="18"
                          />
                        </b-button>
                      </div>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="6">
                  <b-form-group>
                    <label for="lineId">Line ID</label>
                    <b-form-input
                      v-model="contactInfo.lineId"
                      id="lineId"
                      placeholder="Line ID"
                      name="lineId"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group>
                    <label for="line-link-invite">Line Link Invite</label>
                    <b-form-input
                      v-model="contactInfo.lineLinkInvite"
                      id="line-link-invite"
                      placeholder="Line Link Invite"
                      name="lineLinkInvite"
                    />
                  </b-form-group>
                </b-col> -->
              </b-row>
              <b-row class="mt-1">
                <b-col sm="12">
                  <h3>Hr contact info</h3>
                </b-col>
                <b-col sm="12">
                  <b-form-group>
                    <label for="telephone">Telephone <span class="text-red">*</span></label>
                    <validation-provider
                      v-for="(_, index) of contactInfoHr.telephones"
                      :key="index"
                      #default="{ errors }"
                      :name="`HR Telephone ${index + 1}`"
                      rules="required|regex:^([0-9]+)$"
                    >
                      <div :class="`flex gap-4 ${index > 0 ? 'mt-8px' : 'mt-0'}`">
                        <div class="w-full">
                          <b-form-input
                            v-model="contactInfoHr.telephones[index]"
                            :state="errors.length > 0 ? false:null"
                            :id="`hr-telephone${index}`"
                            placeholder="Telephone"
                            :name="`hr-telephone${index}`"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </div>
                        <b-button
                          class="plus-button"
                          variant="outline-secondary"
                          :disabled="index === 2"
                          @click="() => {
                            if (index === 2) return;
                            handleAddOrRemoveArrayClick(contactInfoHr.telephones, index, '')
                          }"
                        >
                          <feather-icon
                            :icon="isNotLastArrayItem(contactInfoHr.telephones, index) ? 'MinusIcon' : 'PlusIcon'"
                            size="18"
                          />
                        </b-button>
                      </div>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group>
                    <label for="email">Email <span class="text-red">*</span></label>
                    <validation-provider
                      v-for="(_, index) of contactInfoHr.emails"
                      :key="index"
                      #default="{ errors }"
                      :name="`HR Email ${index + 1}`"
                      rules="required|email"
                    >
                      <div :class="`flex gap-4 ${index > 0 ? 'mt-8px' : 'mt-0'}`">
                        <div class="w-full">
                          <b-form-input
                            v-model="contactInfoHr.emails[index]"
                            :state="errors.length > 0 ? false:null"
                            :id="`hr-email${index}`"
                            placeholder="Email"
                            :name="`hr-email${index}`"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </div>
                        <b-button
                          class="plus-button"
                          variant="outline-secondary"
                          :disabled="index === 2"
                          @click="() => {
                            if (index === 2) return;
                            handleAddOrRemoveArrayClick(contactInfoHr.emails, index, '')
                          }"
                        >
                          <feather-icon
                            :icon="isNotLastArrayItem(contactInfoHr.emails, index) ? 'MinusIcon' : 'PlusIcon'"
                            size="18"
                          />
                        </b-button>
                      </div>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group>
                    <label for="hr-lineId">Line ID</label>
                    <b-form-input
                      v-model="contactInfoHr.lineId"
                      id="hr-lineId"
                      placeholder="Line ID"
                      name="hr-lineId"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group>
                    <label for="hr-line-link-invite">Line Link Invite</label>
                    <b-form-input
                      v-model="contactInfoHr.lineLinkInvite"
                      id="hr-line-link-invite"
                      placeholder="Line Link Invite"
                      name="hr-lineLinkInvite"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" xl="9">
            <b-card>
              <b-row>
                <b-col sm="12">
                  <h3>Business hour</h3>
                </b-col>
                <b-col sm="12">
                  <b-form-group>
                    <label for="business-hour">Business Hour <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Business hour"
                      rules="required"
                    >
                      <b-form-select
                        v-model="businessHour"
                        :state="errors.length > 0 ? false : null"
                        :options="businessHourOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="business-hour"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-row v-if="businessHour === 'open'" class="flex">
                    <b-col sm="12" md="2">
                      <h6 class="mt-12px nowrap">Monday - Friday</h6>
                    </b-col>
                    <b-col>
                      <validation-provider
                        v-for="(time, index) of businessHourDetail.monday"
                        :key="index"
                        #default="{ errors }"
                        :name="`Businees hour detail ${index + 1}`"
                        rules="required"
                      >
                        <div
                          :class="`flex w-full gap-4 ${index > 0 ? 'mt-8px' : 'mt-0'}`"
                        >
                          <b-form-timepicker v-model="time.start" locale="en" placeholder="Time" />
                          <feather-icon icon="MinusIcon" size="40"/>
                          <b-form-timepicker v-model="time.end" locale="en" placeholder="Time" />
                          <b-button
                            class="plus-button"
                            variant="outline-secondary"
                            :disabled="index === 2"
                            @click="() => {
                              if (index === 2) return;
                              handleAddOrRemoveArrayClick(businessHourDetail.monday, index, { start: '', end: '' })
                            }"
                          >
                            <feather-icon
                              :icon="isNotLastArrayItem(businessHourDetail.monday, index) ? 'MinusIcon' : 'PlusIcon'"
                              size="18"
                            />
                          </b-button>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <!-- <b-row v-if="businessHour === 'open'" class="flex mt-8px">
                    <b-col sm="12" md="2">
                      <h6 class="mt-12px nowrap">Tuesday</h6>
                    </b-col>
                    <b-col>
                      <div 
                        v-for="(time, index) of businessHourDetail.tuesday"
                        :key="index"
                        :class="`flex w-full gap-4 ${index > 0 ? 'mt-8px' : 'mt-0'}`"
                      >
                        <b-form-timepicker v-model="time.start" locale="en" placeholder="Time" />
                        <feather-icon icon="MinusIcon" size="40"/>
                        <b-form-timepicker v-model="time.end" locale="en" placeholder="Time" />
                        <b-button
                          class="plus-button"
                          variant="outline-secondary"
                          @click="() => handleAddOrRemoveArrayClick(businessHourDetail.tuesday, index, { start: '', end: '' })"
                        >
                          <feather-icon
                            :icon="isNotLastArrayItem(businessHourDetail.tuesday, index) ? 'MinusIcon' : 'PlusIcon'"
                            size="18"
                          />
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row v-if="businessHour === 'open'" class="flex mt-8px">
                    <b-col sm="12" md="2">
                      <h6 class="mt-12px nowrap">Wendesday</h6>
                    </b-col>
                    <b-col>
                      <div 
                        v-for="(time, index) of businessHourDetail.wendesday"
                        :key="index"
                        :class="`flex w-full gap-4 ${index > 0 ? 'mt-8px' : 'mt-0'}`"
                      >
                        <b-form-timepicker v-model="time.start" locale="en" placeholder="Time" />
                        <feather-icon icon="MinusIcon" size="40"/>
                        <b-form-timepicker v-model="time.end" locale="en" placeholder="Time" />
                        <b-button
                          class="plus-button"
                          variant="outline-secondary"
                          @click="() => handleAddOrRemoveArrayClick(businessHourDetail.wendesday, index, { start: '', end: '' })"
                        >
                          <feather-icon
                            :icon="isNotLastArrayItem(businessHourDetail.wendesday, index) ? 'MinusIcon' : 'PlusIcon'"
                            size="18"
                          />
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row v-if="businessHour === 'open'" class="flex mt-8px">
                    <b-col sm="12" md="2">
                      <h6 class="mt-12px nowrap">Thursday</h6>
                    </b-col>
                    <b-col>
                      <div 
                        v-for="(time, index) of businessHourDetail.thursday"
                        :key="index"
                        :class="`flex w-full gap-4 ${index > 0 ? 'mt-8px' : 'mt-0'}`"
                      >
                        <b-form-timepicker v-model="time.start" locale="en" placeholder="Time" />
                        <feather-icon icon="MinusIcon" size="40"/>
                        <b-form-timepicker v-model="time.end" locale="en" placeholder="Time" />
                        <b-button
                          class="plus-button"
                          variant="outline-secondary"
                          @click="() => handleAddOrRemoveArrayClick(businessHourDetail.thursday, index, { start: '', end: '' })"
                        >
                          <feather-icon
                            :icon="isNotLastArrayItem(businessHourDetail.thursday, index) ? 'MinusIcon' : 'PlusIcon'"
                            size="18"
                          />
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row v-if="businessHour === 'open'" class="flex mt-8px">
                    <b-col sm="12" md="2">
                      <h6 class="mt-12px nowrap">Friday</h6>
                    </b-col>
                    <b-col>
                      <div 
                        v-for="(time, index) of businessHourDetail.friday"
                        :key="index"
                        :class="`flex w-full gap-4 ${index > 0 ? 'mt-8px' : 'mt-0'}`"
                      >
                        <b-form-timepicker v-model="time.start" locale="en" placeholder="Time" />
                        <feather-icon icon="MinusIcon" size="40"/>
                        <b-form-timepicker v-model="time.end" locale="en" placeholder="Time" />
                        <b-button
                          class="plus-button"
                          variant="outline-secondary"
                          @click="() => handleAddOrRemoveArrayClick(businessHourDetail.friday, index, { start: '', end: '' })"
                        >
                          <feather-icon
                            :icon="isNotLastArrayItem(businessHourDetail.friday, index) ? 'MinusIcon' : 'PlusIcon'"
                            size="18"
                          />
                        </b-button>
                      </div>
                    </b-col>
                  </b-row> -->
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" xl="9">
            <b-card>
              <b-row>
                <b-col sm="12">
                  <h3>Address / Google Map</h3>
                </b-col>
                <b-col sm="12">
                  <b-form-group>
                    <label for="address">Company Address <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Company Address"
                      rules="required"
                    >
                      <b-form-input
                        v-model="addressMap.address"
                        :state="errors.length > 0 ? false:null"
                        id="companyAddress"
                        placeholder="Company Address"
                        name="companyAddress"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col sm="6">
                  <b-form-group>
                    <label for="codezip">Code Zip <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Code zip"
                      rules="required"
                    >
                      <b-form-select
                        v-model="addressMap.codezip"
                        :state="errors.length > 0 ? false:null"
                        :options="zipcodeOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="codezip"
                        @change="handleZipcodeChange"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group>
                    <label for="province">Province <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Province"
                      rules="required"
                    >
                      <b-form-select
                        v-model="addressMap.province"
                        :state="errors.length > 0 ? false : null"
                        :options="provinceOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="province"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group>
                    <label for="state">State <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="State"
                      rules="required"
                    >
                      <b-form-select
                        v-model="addressMap.state"
                        :state="errors.length > 0 ? false : null"
                        :options="stateOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="state"
                        @change="handleStateChange"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group>
                    <label for="city">City <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="City"
                      rules="required"
                    >
                      <b-form-select
                        v-model="addressMap.city"
                        :state="errors.length > 0 ? false : null"
                        :options="cityOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="city"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group>
                    <label for="googlemap">Google Map <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Google Map"
                      rules="required"
                    >
                      <b-form-input
                        v-model="addressMap.googlemap"
                        :state="errors.length > 0 ? false:null"
                        id="googlemap"
                        placeholder="Google Map"
                        name="googlemap"
                        :disabled="maploading"
                        @change="updateMap"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <div class="relative">
                    <div
                      class="overlay flex justify-center items-center"
                      :hidden="!maploading"
                    >
                      <span>Loading...</span>
                    </div>
                    <div
                      id="map"
                      class="w-full"
                      style="height: 200px;"
                    />
                  </div>
                  
                  <!-- <l-map
                    :zoom="zoom"
                    :center="center"
                  >
                    <l-tile-layer :url="url" />
                    <l-marker :lat-lng="center" />
                  </l-map> -->
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" xl="9">
            <b-card>
              <b-row>
                <b-col sm="12">
                  <h3>Social media</h3>
                </b-col>
                <b-col sm="12">
                  <b-form-group>
                    <label for="contact-facebook">Facebook Link <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Facebook link"
                      rules="required"
                    >
                      <b-form-input
                        v-model="social.facebook"
                        :state="errors.length > 0 ? false:null"
                        id="contact-facebook"
                        placeholder="Facebook Link"
                        name="facebook"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group>
                    <label for="contact-facebook">Instagram Link <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Instagram link"
                      rules="required"
                    >
                      <b-form-input
                        v-model="social.instagram"
                        :state="errors.length > 0 ? false:null"
                        id="contact-instagram"
                        placeholder="Instagram Link"
                        name="instagram"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group>
                    <label for="contact-facebook">Line Link <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Line link"
                      rules="required"
                    >
                      <b-form-input
                        v-model="social.line"
                        :state="errors.length > 0 ? false:null"
                        id="contact-line"
                        placeholder="Line Link"
                        name="line"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group>
                    <label for="contact-facebook">Linkedin Link <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Linkedin link"
                      rules="required"
                    >
                      <b-form-input
                        v-model="social.linkedin"
                        :state="errors.length > 0 ? false:null"
                        id="contact-linkedin"
                        placeholder="Linkedin Link"
                        name="linkedin"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <div class="fixed-footer justify-end">
      <div>
        <b-button variant="outline-secondary">
          <span>Cancel</span>
        </b-button>
        <b-button
          class="ml-1"
          variant="success"
          @click="handleUpdate"
        >
          <span>Save</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BFormTextarea, BFormSelect, BFormTimepicker, BFormFile, BImg, BBadge, BTab, BTabs
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, regex } from '@validations'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import useContact from './useContact'
import contactStoreModule from './contactStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { searchAddressByDistrict, searchAddressByZipcode } from 'thai-address-database'

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
/* eslint-disable global-require */

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormTextarea,
    BFormTimepicker,
    BBadge,
    BFormFile,
    BImg,
    BTab,
    BTabs,
    BFormSelect,

    LMap,
    LTileLayer,
    LMarker,
  },
  props: {},
  data() {
    return {
      required,
      email,
      regex,
      stateOptions: [],
      cityOptions: [],
      provinceOptions: [],
      contactInfo: {
        companyName: '',
        telephones: [''],
        emails: [''],
        lineId: '',
        lineLinkInvite: ''
      },
      contactInfoHr: {
        telephones: [''],
        emails: [''],
        lineId: '',
        lineLinkInvite: ''
      },
      businessHour: 'none',
      businessHourDetail: {
        monday: [{
          start: '',
          end: '',
        }],
        tuesday: [{
          start: '',
          end: '',
        }],
        wendesday: [{
          start: '',
          end: '',
        }],
        thursday: [{
          start: '',
          end: '',
        }],
        friday: [{
          start: '',
          end: '',
        }],
      },
      addressMap: {
        address: '',
        province: '',
        state: '',
        city: '',
        codezip: '',
        googlemap: '',
      },
      social: {
        facebook: '',
        instagram: '',
        line: '',
        linkedin: '',
      },
      zoom: 16,
      maploading: false,
      center: [ 13.7462411, 100.5347402 ],
    }
  },
  methods: {
    async handleUpdate() {
      const isFormValid = await this.$refs.form.validate();
      if (!isFormValid) return;

      const contact = {
        contactInfo: this.contactInfo,
        contactInfoHr: this.contactInfoHr,
        businessHour: this.businessHour,
        businessHourDetail: this.businessHourDetail,
        addressMap: this.addressMap,
        social: this.social,
      }
      const { status } = await this.updateContact(contact);
      if (status !== 200) return this.makeToast(false);

      this.makeToast(true);
    },
    async updateMap(googlemap) {
      this.maploading = true
      try {
        const { data, status } = await this.getGeo(googlemap);
        if (status !== 200) return;

        const position = { lat: data.lat, lng: data.lng }
        const { Map } = await google.maps.importLibrary("maps");
        const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

        const map = new Map(document.getElementById("map"), {
          center: position,
          zoom: this.zoom,
          mapId: 'akara',
          streetViewControl: false,
          mapTypeControl: false,
        });

        new AdvancedMarkerElement({
          map: map,
          position: position,
        });
      } catch (exception) {
        console.error(exception)
      } finally {
        this.maploading = false
      }
    },
    isNotLastArrayItem(array, index) {
      return array.length > 1 && index !== array.length - 1;
    },
    handleAddOrRemoveArrayClick(array, index, defaultValue) {
      if (this.isNotLastArrayItem(array, index)) {
        array.splice(index, 1);
      } else {
        array.push(defaultValue);
      }
    },
    handleZipcodeChange() {
      const addresses = searchAddressByZipcode(this.addressMap.codezip)
      const province = new Set(addresses.map(address => address.province))
      const district = new Set(addresses.map(address => address.district))
      const amphoe = new Set(addresses.map(address => address.amphoe))
      
      if (province.size === 1) {
        this.addressMap.province = [...province][0]
      } else {
        this.addressMap.province = ''
      }
      if (district.size === 1) {
        this.addressMap.state = [...district][0]
      } else {
        this.addressMap.state = ''
      }
      if (amphoe.size === 1) {
        this.addressMap.city = [...amphoe][0]
      } else {
        this.addressMap.city = ''
      }
      
      this.provinceOptions = [...this.provinceDefaultOptions, ...this.toOptions(province)]
      this.stateOptions = [...this.stateDefaultOptions, ...this.toOptions(district)]
      this.cityOptions = [...this.cityDefaultOptions, ...this.toOptions(amphoe)]
    },
    handleStateChange() {
      const addresses = searchAddressByDistrict(this.addressMap.state)
      const amphoe = new Set(addresses.filter(address => address.zipcode === this.addressMap.codezip).map(address => address.amphoe))

      if (amphoe.size === 1) {
        this.addressMap.city = [...amphoe][0]
      } else {
        this.addressMap.city = ''
      }

      this.cityOptions = [...this.cityDefaultOptions, ...this.toOptions(amphoe)]
    },
    toOptions(array) {
      const temp = [...array]
      return temp.map(item => { return { text: item, value: item }})
    },
    makeToast(success) {
      this.$bvToast.toast(
        (
          <div class="d-flex">
            <feather-icon
              class={success ? 'text-success' : 'text-danger'}
              icon={success ? 'CheckCircleIcon' : 'XCircleIcon'}
              size="24"
            />
            <div class="d-flex flex-column">
              <h4 class={success ? 'ml-1 text-success' : 'ml-1 text-danger'}>{success ? 'Update Succeeded!' : 'Updated failed!' }</h4>
              <div class="ml-1 font-14px">{success ? 'Contact have been updated.' : 'Unexpected error occurred.'}</div>
            </div>
          </div>
        ),
        {
          headerClass: 'd-none',
          solid: true,
        },
      )
    },
  },
  async created() {
    const app = document.getElementById('app')
    const appLoading = document.getElementById('loading-bg')
    const appLoadingImage = document.querySelector('#loading-bg > img')
    if (appLoading) {
      app.style.overflow = 'hidden'
      appLoading.style.zIndex = '9'
      appLoading.style.display = 'block'
      appLoadingImage.src = `/loading.gif?${Math.random()}`
    }

    try {
      const { data } = await this.fetchContact();
      this.contactInfo = data.contactInfo;
      this.contactInfoHr = data.contactInfoHr;
      this.businessHour = data.businessHour;
      this.businessHourDetail = data.businessHourDetail;
      this.social = data.social;
      this.addressMap.codezip = data.addressMap.codezip;
      this.handleZipcodeChange();
      this.addressMap = data.addressMap;
      if (data.addressMap.googlemap) {
        await this.updateMap(data.addressMap.googlemap);
      }
      if (appLoading) {
        app.style.overflow = 'auto'
        appLoading.style.display = 'none'
      }
    } catch (error) {
      this.toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching content',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  },
  setup() {
    const CONTACT_APP_STORE_MODULE_NAME = 'app-contact'

    // Register module
    if (!store.hasModule(CONTACT_APP_STORE_MODULE_NAME)) store.registerModule(CONTACT_APP_STORE_MODULE_NAME, contactStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONTACT_APP_STORE_MODULE_NAME)) store.unregisterModule(CONTACT_APP_STORE_MODULE_NAME)
    })

    const {
      businessHourOptions,
      provinceOptions,
      provinceDefaultOptions,
      stateDefaultOptions,
      cityDefaultOptions,
      zipcodeOptions,
      getGeo,
      fetchContact,
      updateContact,
    } = useContact()

    return {
      businessHourOptions,
      provinceOptions,
      provinceDefaultOptions,
      stateDefaultOptions,
      cityDefaultOptions,
      zipcodeOptions,
      getGeo,
      fetchContact,
      updateContact,
    }
  },
}
</script>
<style>
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 1;
    opacity: 0.7;
  }
</style>